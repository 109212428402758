import { useState, useEffect } from "react";
import { usePopper } from "react-popper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBooks } from "@fortawesome/pro-regular-svg-icons";
import { Popover } from "@headlessui/react";

export default function BookingwaDocs({ handleSubmit, platform, ...props }) {
    let default_options = [];

    if (window.location.hostname == "charlie.romiapp.co.za") {
        default_options = [
            {
                value: "brochure",
                label: "Brochure",
                link: "brochure",
                siteurl: "https://charlie-api.romiapp.co.za/charlie_docs/Charlie%20&%20the%20Chairman%20-%20Brochure.pdf",
            },
            {
                value: "floorplans",
                label: "Floor Plans",
                link: "floorplans",
                siteurl: "https://charlie-api.romiapp.co.za/charlie_docs/Charlie%20&%20the%20Chairman%20-%20Floor%20Plans.pdf",
            },
            {
                value: "proposal",
                label: "PropR Proposal",
                link: "proposal",
                siteurl: "https://charlie-api.romiapp.co.za/charlie_docs/Charlie%20&%20the%20Chairman%20-%20Propr%20Proposal.pdf",
            },
        ];
    } else if (window.location.hostname == "ladybacks.romiapp.co.za" || window.location.hostname == "localhost") {
        default_options = [
            {
                value: "brochure",
                label: "Brochure",
                link: "brochure",
                siteurl: "https://ladybacks-api.romiapp.co.za/wa_images/Lady_Backs_-_Brochure.pdf",
            },
            {
                value: "floorplans",
                label: "Floor Plans",
                link: "floorplans",
                siteurl: "https://ladybacks-api.romiapp.co.za/wa_images/Floorplans_Lady_Backs_FA.pdf",
            },
        ];
    }

    let [referenceElement, setReferenceElement] = useState();
    let [popperElement, setPopperElement] = useState();
    let [options, setOptions] = useState(default_options);
    let { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "top",
    });

    return (
        <Popover>
            <Popover.Button
                ref={setReferenceElement}
                className="ml-4 relative inline-block text-white bg-primary px-2  py-1 rounded-xl hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
            >
                <FontAwesomeIcon className="h-4 w-4" aria-hidden="true" icon={faBooks} />
            </Popover.Button>

            <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper} className="bg-white rounded-lg pb-4  z-20">
                <h1 className="bg-dark_background text-white p-2 rounded-t-lg">Documents</h1>
                <div className="flex flex-col items-start px-4 mt-2">
                    {options?.map((o) => (
                        <button key={"bpopt_" + o.value} type="button" className="text-gray-600 hover:text-primary" onClick={() => props.setFieldValue("message", o.siteurl)}>
                            {o.label}
                        </button>
                    ))}
                </div>
            </Popover.Panel>
        </Popover>
    );
}
