import React, { useEffect, useState, useRef, Fragment, useCallback } from "react";
import axios from "axios";
import Modal from "react-modal";
import { Link } from "react-router-dom";

export default function AdminLookupsCampusPage() {
    const [lookups, setLookups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [group4Lookups, setGroup4Lookups] = useState([]);
    const [selectedLookup, setSelectedLookup] = useState(null);
    const [selectedFundingType, setSelectedFundingType] = useState("");
    const [linkedInstitutions, setLinkedInstitutions] = useState({});
    const [allLinks, setAllLinks] = useState([]);
    const [properties, setProperties] = useState([]);

    let lookupGroupTypes = ["?", "?", "Ethnicity", "Title", "Institution", "Campus", "?"];

    const funding_types = [
        { label: "NSFAS UJ", value: 4 },
        { label: "NSFAS WITS", value: 5 },
        // { label: "CJC", value: 6 },
        { label: "NSFAS TVET", value: 1 },
        { label: "Bursary", value: 2 },
        { label: "Private", value: 3 },
    ];

    useEffect(() => {
        setLoading(true);
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}uc_lookups`,
        })
            .then((response) => {
                const lookupsData = Object.entries(response.data);
                setLookups(lookupsData);

                const group4 = lookupsData.find(([groupName]) => groupName === "4");
                setGroup4Lookups(group4 ? group4[1] : []);

                // Fetch linked institutions (this assumes your API provides it)
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}uc_lookup_links`,
                }).then((res) => {
                    const links = res.data.reduce((acc, link) => {
                        if (!acc[link.lookup_id]) acc[link.lookup_id] = [];
                        acc[link.lookup_id].push(link?.linked_lookup?.name ?? "Unsure"); // Assuming linked_name is the name of the linked institution
                        return acc;
                    }, {});
                    setLinkedInstitutions(links);

                    //Run through and make sure that funding_type_ids are numbers
                    const processedData = res.data.map((item) => {
                        return {
                            ...item,
                            selected_funding_types: (item.selected_funding_types || []).map(Number), // Convert all funding_type_ids to numbers
                            selected_buildings: (item.selected_buildings || []).map(Number), // Convert all building_ids to numbers
                        };
                    });

                    setAllLinks(processedData);
                });

                setLoading(false);
            })
            .catch((error) => {
                alert("Failed to get lookups");
                setLoading(false);
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}properties`,
        })
            .then((response) => {
                setProperties(response.data);
                setLoading(false);
            })
            .catch((error) => {
                alert("properties failed");
            });
    }, []);

    const handleOpenModal = (lookup, type) => {
        if (type == 1) {
            setSelectedLookup(lookup);
            setIsModalOpen(1);
        } else if (type == 2) {
            setIsModalOpen(2);
        }
    };

    const handleCloseModal = () => {
        setSelectedLookup(null);
        setIsModalOpen(false);
    };

    const handleSelectLookup = (lookup) => {
        // Implement the logic to link this lookup to the UCLookupLink model
        axios
            .post(`${process.env.REACT_APP_API_URL}uc_lookup_links`, {
                lookup_id: selectedLookup.value,
                linked_to: lookup.value,
                linked_data: selectedLookup.linked_data,
            })
            .then((response) => {
                setLinkedInstitutions((prevState) => ({
                    ...prevState,
                    [selectedLookup.value]: [...(prevState[selectedLookup.value] || []), lookup.label],
                }));

                alert("Lookup linked successfully");
                handleCloseModal();
            })
            .catch((error) => {
                alert("Failed to link lookup");
            });
    };

    // Handle building selection
    const handleStudentTypeSelection = (link, funding_type_id, isChecked) => {
        // Update the selected buildings for this link
        setAllLinks((prevLinks) =>
            prevLinks.map((item) =>
                item === link
                    ? {
                          ...item,
                          selected_funding_types: isChecked ? [...(item.selected_funding_types || []), funding_type_id] : item.selected_funding_types.filter((id) => id !== funding_type_id),
                      }
                    : item
            )
        );
    };

    console.log(allLinks);

    // Handle building selection
    const handleBuildingSelection = (link, buildingId, isChecked) => {
        // Update the selected buildings for this link
        setAllLinks((prevLinks) =>
            prevLinks.map((item) =>
                item === link
                    ? {
                          ...item,
                          selected_buildings: isChecked ? [...(item.selected_buildings || []), buildingId] : item.selected_buildings.filter((id) => id !== buildingId),
                      }
                    : item
            )
        );
    };

    // Handle saving the link with funding type and buildings
    const handleSaveLink = (link) => {
        console.log(link);
        // Send the selected funding type and buildings to the backend
        const payload = {
            lookup_id: link.lookup_id,
            linked_lookup_id: link.linked_lookup_id,
            linked_data: link?.lookup?.linked_data,
            building_ids: link.selected_buildings,
            funding_type_ids: link.selected_funding_types,
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}link-funding-type`, payload)
            .then((response) => {
                alert("Link saved successfully!");
                // Optionally refresh data or update the UI
            })
            .catch((error) => {
                console.error("Error saving link:", error);
            });
    };

    return (
        <div className="px-1 sm:px-4 my-2 xl:my-0 xl:p-4 h-screen w-full">
            <div className="flex justify-between mb-8 items-center">
                <h1 className="text-lg font-bold">Lookups</h1>

                <div className="space-x-2">
                    <Link to="/settings_new" className="bg-primary text-white px-4 py-2 rounded-full">
                        Back to Settings
                    </Link>
                </div>
            </div>

            {lookups.length > 0 &&
                lookups
                    .filter(([groupName]) => groupName === "5")
                    .map(([groupName, lookup_group], index) => (
                        <div key={index} className="mb-6">
                            <h2 className="text-xl font-semibold text-gray-700 mb-2">{lookupGroupTypes[groupName]}</h2>
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Name
                                        </th>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-1/5">
                                            Linked Institutions
                                        </th>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-1/5">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {lookup_group.map((lookup) => (
                                        <tr key={lookup.value}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{lookup.label}</td>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {linkedInstitutions[lookup.value] ? linkedInstitutions[lookup.value].join(", ") : <span>No links</span>}
                                            </td>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {groupName === "5" && <button onClick={() => handleOpenModal(lookup, 1)}>Institution Links</button>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}

            <h2 className="text-xl font-semibold text-gray-700 mb-2">Funding Type Links</h2>
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Institution
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Campus
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Funding Type
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Buildings
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {allLinks.length > 0 &&
                        allLinks.map((link, index) => (
                            <tr key={index}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{link.linked_lookup?.name}</td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{link.lookup?.name}</td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {funding_types?.map((funding_type) => (
                                        <div key={funding_type.value} className="flex items-center">
                                            <input
                                                id={`funding_type-${funding_type.value}-${index}`}
                                                name={`funding_type-${funding_type.value}`}
                                                type="checkbox"
                                                checked={link.selected_funding_types?.includes(funding_type.value) || false}
                                                onChange={(e) => handleStudentTypeSelection(link, funding_type.value, e.target.checked)}
                                                className="form-checkbox"
                                            />
                                            <label htmlFor={`building-${funding_type.value}-${index}`} className="ml-2">
                                                {funding_type.label}
                                            </label>
                                        </div>
                                    ))}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {properties?.map((building) => (
                                        <div key={building.value} className="flex items-center">
                                            <input
                                                id={`building-${building.value}-${index}`}
                                                name={`building-${building.value}`}
                                                type="checkbox"
                                                checked={link.selected_buildings?.includes(building.value) || false}
                                                onChange={(e) => handleBuildingSelection(link, building.value, e.target.checked)}
                                                className="form-checkbox"
                                            />
                                            <label htmlFor={`building-${building.value}-${index}`} className="ml-2">
                                                {building.label}
                                            </label>
                                        </div>
                                    ))}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    <button onClick={() => handleSaveLink(link)} className="text-blue-600 hover:text-blue-900">
                                        Save
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            <Modal
                isOpen={isModalOpen === 1}
                onRequestClose={handleCloseModal}
                contentLabel="Select Institution"
                style={{
                    content: {
                        width: "400px", // Set the width of the modal
                        height: "500px", // Set the height of the modal
                        margin: "auto", // Center the modal
                        padding: "20px", // Add some padding
                    },
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: Customize the overlay
                    },
                }}
            >
                <h2 className="text-center mb-4 font-bold">Select an Institution</h2>
                <ul>
                    {group4Lookups.map((lookup) => (
                        <li key={lookup.value}>
                            <button onClick={() => handleSelectLookup(lookup)}>{lookup.label}</button>
                        </li>
                    ))}
                </ul>

                <div className="mt-4 text text-center">
                    <button className="bg-primary px-4 py-2 rounded text-white" onClick={handleCloseModal}>
                        Close
                    </button>
                </div>
            </Modal>
        </div>
    );
}
