import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import { Field } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import ErrorBoundary from "../../containers/ErrorBoundary";

import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import CustomButton from "../Helpers/CustomButton";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsTextArea from "../Forms/SettingsTextarea";
import { closeModal } from "../../store/modal/modalReducer";
import { getTemplates } from "../../store/template/templateActions";
import TemplateFileUpload from "../Forms/TemplateFileUpload";

export default function SlideoverTemplateForm(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);

  var initial_values,
    type,
    api_method,
    button = "";

  if (props.action == "edit") {
    initial_values = {
      id: props.data.data_id,
      title: props.data.data_title,
      message_email: props.data.data_message_email,
      message_whatsapp: props.data.data_message_whatsapp,
      message_sms: props.data.data_message_sms,
      category: props.data.data_category.id,
      files: props.data.data_files ? JSON.parse(props.data.data_files) : [],
      keep_files: true,
      attach_aos: 0,
    };

    type = "Editing " + props.data.data_title;
    api_method = "update_template";
    button = "Update";
  } else {
    initial_values = {
      id: "",
      title: "",
      message_email: "",
      message_whatsapp: "",
      message_sms: "",
      category: "",
      files: [],
      keep_files: false,
      attach_aos: 0,
    };

    type = "Create a " + props.data.type;
    api_method = "save_template";
    button = "Create";
  }

  //Fix format of Categories
  let categories = props.data.categories.map((cat) => {
    return {
      value: cat.id,
      label: cat.name,
    };
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => dispatch(closeModal())}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <ErrorBoundary>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll bg-light_background">
                        <div className="bg-dark_background py-6">
                          <div className=" px-4 sm:px-6">
                            <div className="flex items-start justify-between">
                              <Dialog.Title className="text-xl leading-6 font-light text-white uppercase">
                                {type}{" "}
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md text-white hover:text-gray-200"
                                  onClick={() => dispatch(closeModal())}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <FontAwesomeIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                    icon={faTimes}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="relative mt-6 flex-1 px-4 sm:px-6 ">
                          <Formik
                            initialValues={initial_values}
                            validationSchema={Yup.object({
                              title: Yup.string().required(
                                "You need to type in a title"
                              ),
                              category: Yup.string().required(
                                "Please choose a Category"
                              ),
                            })}
                            onSubmit={async (
                              values,
                              { setSubmitting, setErrors, resetForm }
                            ) => {
                              setSubmitting(true);

                              var files = [];
                              var form_data = new FormData();
                              form_data.append("id", values.id);
                              form_data.append(
                                "message_email",
                                values.message_email
                              );
                              form_data.append(
                                "message_whatsapp",
                                values.message_whatsapp
                              );
                              form_data.append(
                                "message_sms",
                                values.message_sms
                              );
                              form_data.append("category", values.category);
                              form_data.append("title", values.title);
                              form_data.append("keep_files", values.keep_files);
                              form_data.append("attach_aos", values.attach_aos);
                              if (values.files.length > 0) {
                                values.files.map((file) => {
                                  form_data.append("files[]", file);
                                  files.push(file.name);
                                });
                              }

                              await axios
                                .post(
                                  process.env.REACT_APP_API_URL + api_method,
                                  form_data
                                )
                                .then(() => {
                                  toast(
                                    <NotificationToast
                                      title={values.title + " saved"}
                                      message=""
                                    />
                                  );
                                })
                                .catch((error) => {
                                  toast(
                                    <NotificationToast
                                      title="Something went wrong"
                                      message={error.response}
                                      type="error"
                                    />
                                  );
                                })
                                .finally(() => {
                                  dispatch(closeModal());
                                  resetForm();
                                  dispatch(getTemplates());
                                });
                            }}
                          >
                            {({
                              values,
                              errors,
                              onChange,
                              isValid,
                              dirty,
                              handleSubmit,
                              setFieldValue,
                              isSubmitting,
                              handleChange,
                            }) => (
                              <Form
                                onSubmit={handleSubmit}
                                className="space-y-8"
                              >
                                <SettingsSelect
                                  label="Category"
                                  name="category"
                                  errors={errors.category}
                                  options={categories}
                                />
                                <SettingsTextInput
                                  name="title"
                                  placeholder="Name"
                                  errors={errors.title}
                                  value={values.title}
                                />
                                <SettingsTextArea
                                  name="message_whatsapp"
                                  placeholder="Message - Whatsapp"
                                  errors={errors.message_whatsapp}
                                  emojis={true}
                                  value={values.message_whatsapp}
                                  wysiwyg={false}
                                />
                                <SettingsTextArea
                                  name="message_sms"
                                  placeholder="Message - SMS"
                                  errors={errors.message_sms}
                                  emojis={true}
                                  value={values.message_sms}
                                  wysiwyg={false}
                                />
                                <SettingsTextArea
                                  name="message_email"
                                  placeholder="Message - Email"
                                  errors={errors.message_email}
                                  emojis={true}
                                  setFieldValue={setFieldValue}
                                  handleChange={handleChange}
                                  value={values.message_email}
                                  wysiwyg={true}
                                />
                                <p className="text-sm text-gray-600">
                                  Tags: [me], [regards], [name], [surname],
                                  [booking_reference], [edit_profile],
                                  [lease_link], [invoice_link], [documents_link]
                                  [MaintenanceCall], [Viewing],
                                  [Move_Out_Inspection], [Move_In_Inspection],
                                  [move_in_form],
                                  [Move_In_Inspection_Hyperlink],
                                  [move_in_form_Hyperlink],
                                  [Move_Out_Inspection_Hyperlink],
                                  [Maintenance_Call_Hyperlink],
                                  [Documents_Hyperlink],
                                  [Edit_Profile_Hyperlink], [Invoice_Hyperlink],
                                  [Lease_Hyperlink]
                                  {process.env.REACT_APP_COLOUR == "QL" && (
                                    <span>
                                      , [Viewing | The Ox], [Viewing | Forest
                                      Views], [Viewing | Delve Deeper], [Viewing
                                      | The Unison], [Viewing | Homage],
                                      [Viewing | Kent], [unit_no]
                                    </span>
                                  )}
                                  {process.env.REACT_APP_COLOUR == "QP" && (
                                    <span>, [unit_no], [aos_attachment]</span>
                                  )}
                                </p>

                                {process.env.REACT_APP_COLOUR == "QP" && (
                                  <div>
                                    <label>
                                      Attach AOS PDF
                                      <Field
                                        type="checkbox"
                                        name="attach_aos"
                                        onChange={(e) => {
                                          const { checked } = e.target;
                                          if (!checked) {
                                            setFieldValue("attach_aos", 0);
                                          } else {
                                            setFieldValue("attach_aos", 1);
                                          }
                                        }}
                                      />
                                    </label>
                                    {values.attach_aos}
                                  </div>
                                )}

                                {api_method === "update_template" && (
                                  <label>
                                    Keep same files
                                    <Field
                                      type="checkbox"
                                      name="keep_files"
                                      onChange={(e) => {
                                        const { checked } = e.target;
                                        if (!checked) {
                                          setFieldValue("files", []);
                                          setFieldValue("keep_files", false);
                                        } else {
                                          setFieldValue(
                                            "files",
                                            JSON.parse(props.data.data_files)
                                          );
                                          setFieldValue("keep_files", true);
                                        }
                                      }}
                                    />
                                  </label>
                                )}
                                {values.keep_files === false && (
                                  <>
                                    <TemplateFileUpload
                                      setFieldValue={setFieldValue}
                                    />
                                    <p className="!mt-1 text-center text-red-500 font-bold">
                                      Whatsapp has a 500kb limit
                                    </p>
                                  </>
                                )}
                                <div>
                                  {values.files &&
                                    values.files.map((file, i) => (
                                      <li
                                        key={i}
                                        className="flex-inline align-center"
                                      >
                                        {file.name}
                                        <FontAwesomeIcon
                                          icon={faTimes}
                                          className="h-5 w-5 text-red-400 cursor-pointer"
                                          onClick={() =>
                                            setFieldValue(
                                              "files",
                                              values.files.filter(
                                                (new_files) =>
                                                  new_files !== file
                                              )
                                            )
                                          }
                                        />
                                      </li>
                                    ))}
                                </div>
                                <div className="flex flex-shrink-0 space-x-4 justify-end px-4 py-4">
                                  <CustomButton
                                    type="button"
                                    onClick={() => dispatch(closeModal())}
                                    text="Cancel"
                                  />
                                  <SubmitButton
                                    name="Submit"
                                    disabled={
                                      !isValid || isSubmitting || !dirty
                                    }
                                    text={button}
                                  />
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </Dialog>
    </Transition.Root>
  );
}
