import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { faFileInvoiceDollar, faSave, faPlus, faFilePdf, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
import { Formik, Form, Field } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import CustomButton from "../Helpers/CustomButton";
import QuickFormSelect from "../Forms/QuickFormSelect";
import QuickFormText from "../Forms/QuickFormText";
import LoadingComponent from "../Helpers/LoadingComponent";

export default function GridInvoiceInformation({ setIgnoreRefresh }) {
    const dispatch = useDispatch();
    const [booking, setBooking] = useState([]);
    const { current_booking } = useSelector((state) => state.booking);
    const { current_staff } = useSelector((state) => state.auth);
    const [units, setUnits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [aosRecords, setAosRecords] = useState([]);
    const [aosButtonLoading, setaosButtonLoading] = useState(false);
    var aosButtonLoadingText = "Generating AOS";

    useEffect(() => {
        getLocalBooking();
        getUnits(0);
        getAosRecords(); // Fetch AOS records
    }, []);

    async function getLocalBooking() {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}user_profile/${current_booking.user.id}`,
        }).then((response) => {
            setBooking(response.data);
            setLoading(false);
        });
    }

    async function getUnits(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}units/${id}`,
        }).then((response) => {
            setUnits(response.data);
        });
    }

    // Function to get the AOS records
    async function getAosRecords() {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}aos_records/${current_booking.id}`, // Assuming there's an endpoint for AOS records
        })
            .then((response) => {
                setAosRecords(response.data);
            })
            .catch(() => {
                toast(<NotificationToast title="Failed to retrieve AOS records" message="Please try again." type="error" />);
            });
    }

    async function addNewAosRecord() {
        await axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}aos_create`,
            data: {
                booking_id: current_booking.id,
            },
        })
            .then((response) => {
                getAosRecords();
                toast(<NotificationToast title="AOS Record Added" message="A new AOS record has been successfully added." />);
            })
            .catch((error) => {
                toast(<NotificationToast title="Error" message="Failed to add a new AOS record." type="error" />);
            });
    }

    async function generate_aos(recordId) {
        setaosButtonLoading(true);

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}generate_aos/${recordId}`,
        })
            .then(() => {
                getAosRecords();
                toast(<NotificationToast title={"AOS generated"} message={"You can now send the link to the applicant."} />);
                // setIgnoreRefresh(true);
                // dispatch(getBooking(current_booking.id));
            })
            .catch((response) => {
                toast(<NotificationToast title={"AOS failed"} message={response.response.data} list={true} type="error" />);
            })
            .finally(() => {
                setaosButtonLoading(false);
            });
    }

    if (loading) {
        return <LoadingComponent />;
    }

    const statusOptions = [
        { value: "pending", label: "Pending" },
        { value: "reserved", label: "Reserved" },
        { value: "signed", label: "Signed" },
        { value: "cancelled", label: "Cancelled" },
    ];

    async function download_file(file) {
        setLoading(true);
        await axios({
            method: "get",
            responseType: "blob",
            url: `${process.env.REACT_APP_API_URL}download_file/${file.id}`,
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", file.name + "." + file.extension);
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                toast(<NotificationToast title="File Missing" message={"Something went wrong, we could not download this file!"} type="error" />);
            })
            .finally(() => setLoading(false));
    }

    async function delete_aos(id) {
        if (window.confirm("Are you sure you want to delete this AOS?")) {
            await axios({
                method: "delete",
                url: `${process.env.REACT_APP_API_URL}aos_delete/${id}`,
            })
                .then((response) => {
                    getAosRecords();
                })
                .catch((error) => {
                    alert("AOS failed to delete");
                });
        }
    }

    return (
        <div className="scroll-smooth">
            {/* AOS Records Display */}
            {aosRecords.length > 0 ? (
                <div>
                    {aosRecords.map((record, index) => (
                        <div key={index} className="border p-4 rounded mb-4">
                            {/* Form for Updating Information specific to each AOS record */}
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    id: record.id,
                                    unit_id: record.unit_id,
                                    status: record.status,
                                    parking_bay: record.parking_bay,
                                    store_no: record.store_no,
                                    exclusive_use: record.exclusive_use,
                                }}
                                onSubmit={async (values, { setSubmitting }) => {
                                    let submitData = {
                                        id: record.id,
                                        unit_id: values.unit_id,
                                        status: values.status,
                                        booking_id: current_booking.id,
                                        parking_bay: values.parking_bay,
                                        store_no: values.store_no,
                                        exclusive_use: values.exclusive_use,
                                    };

                                    await axios({
                                        method: "put",
                                        url: `${process.env.REACT_APP_API_URL}aos_save`, // Assuming there's an endpoint for AOS save
                                        data: submitData,
                                    })
                                        .then(() => {
                                            getAosRecords();
                                            toast(<NotificationToast title="Success" message="Data has been updated successfully" />);
                                        })
                                        .catch((error) => {
                                            toast(<NotificationToast title="Something went wrong" message="" type="error" />);
                                        });

                                    setSubmitting(false);
                                }}
                            >
                                {({ values, errors, handleSubmit, setFieldValue, setFieldTouched, isSubmitting, isValid, dirty }) => (
                                    <Form onSubmit={handleSubmit} className="rounded-3xl">
                                        <div className="pb-6 grid gap-x-2 gap-y-8 grid-cols-2">
                                            {(process.env.REACT_APP_COLOUR === "QL" || process.env.REACT_APP_COLOUR === "QP") && (
                                                <>
                                                    <QuickFormSelect
                                                        label="Unit"
                                                        name="unit_id"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.unit_id}
                                                        error={errors.unit_id}
                                                        options={units.length > 0 ? units : "none"}
                                                        disabled={current_staff?.hideAppInfoUpdate == 1 ? true : false}
                                                    />

                                                    <QuickFormSelect
                                                        label="Status"
                                                        name="status"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.status}
                                                        error={errors.status}
                                                        options={statusOptions.length > 0 ? statusOptions : "none"}
                                                        disabled={current_staff?.hideAppInfoUpdate == 1 ? true : false}
                                                    />

                                                    <Field name="parking_bay" as={QuickFormText} label="Parking Bay" />
                                                    <Field name="store_no" as={QuickFormText} label="Store No" />
                                                    <Field name="exclusive_use" as={QuickFormText} label="Exclusive Use" />

                                                    <div className="col-span-2">
                                                        <label className="text-sm text-dark_background">Unit Type</label>
                                                        <div className="rounded-lg py-2 pl-2 truncate xl:w-34 font-light text-sm focus:outline-none focus:ring-primary focus:border-primary border">
                                                            {units?.find((unit) => unit.id == values.unit_id)?.unit_type_name ?? "TBC"}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label className="text-sm text-dark_background">Price</label>
                                                        <div className="rounded-lg py-2 pl-2 truncate xl:w-34 font-light text-sm focus:outline-none focus:ring-primary focus:border-primary border">
                                                            {units?.find((unit) => unit.id == values.unit_id)?.price ?? "TBC"}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label for="unit_id" class="text-sm text-dark_background">
                                                            Launch Price
                                                        </label>
                                                        <div className="rounded-lg py-2 pl-2 truncate xl:w-34 font-light text-sm focus:outline-none focus:ring-primary focus:border-primary border">
                                                            {units?.find((unit) => unit.id == values.unit_id)?.launch_day_price ?? "TBC"}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label for="unit_id" class="text-sm text-dark_background">
                                                            Internal Area
                                                        </label>
                                                        <div className="rounded-lg py-2 pl-2 truncate xl:w-34 font-light text-sm focus:outline-none focus:ring-primary focus:border-primary border">
                                                            {units?.find((unit) => unit.id == values.unit_id)?.area ?? "TBC"}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label for="unit_id" class="text-sm text-dark_background">
                                                            External Area
                                                        </label>
                                                        <div className="rounded-lg py-2 pl-2 truncate xl:w-34 font-light text-sm focus:outline-none focus:ring-primary focus:border-primary border">
                                                            {units?.find((unit) => unit.id == values.unit_id)?.area_external ?? "TBC"}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        {!current_staff.building_manager && current_staff?.hideAppInfoUpdate != 1 && (
                                            <SubmitButton name="Save" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} icon={faSave} text="Update" style={{ width: "100%" }} />
                                        )}

                                        {record.unit_id > 0 && (
                                            <CustomButton
                                                text="Generate AOS"
                                                styling="w-full mt-4"
                                                icon={faFileInvoiceDollar}
                                                onClick={() => generate_aos(record.id)}
                                                loading={aosButtonLoading ? 1 : 0}
                                                loadingtext={aosButtonLoadingText}
                                            />
                                        )}

                                        {record.file?.id && <CustomButton text="Download AOS PDF" styling="w-full mt-4" icon={faFilePdf} onClick={() => download_file(record.file)} />}

                                        {!current_staff.building_manager && current_staff?.hideAppInfoUpdate != 1 && (
                                            <CustomButton text="Delete AOS" styling="w-full mt-4 bg-red-500" icon={faTrash} onClick={() => delete_aos(record.id)} />
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No AOS records found.</p>
            )}

            {/* Add New AOS Button */}
            {!current_staff.building_manager && current_staff?.hideAppInfoUpdate != 1 && <CustomButton text="Add AOS Record" styling="w-full mt-4" icon={faPlus} onClick={() => addNewAosRecord()} />}
        </div>
    );
}
