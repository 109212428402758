import { useEffect, Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTableLayout } from "@fortawesome/pro-regular-svg-icons";
import { Menu, Transition } from "@headlessui/react";
import axios from "axios";

export default function BookingTemplate({ handleSubmit, current_booking, platform, setFieldValue }) {
    const [templates, setTemplates] = useState([]);
    const [whatsappTemplates, setWhatsappTemplates] = useState([]);
    const [templateCategories, setTemplateCategories] = useState([]);
    const [years, setYears] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [aosRecords, setAosRecords] = useState([]);

    // Load AOS records related to the current booking

    useEffect(() => {
        if (process.env.REACT_APP_COLOUR == "QP") {
            axios.get(`${process.env.REACT_APP_API_URL}aos_records/${current_booking?.id}`).then((response) => {
                setAosRecords(response.data);
            });
        }
    }, [current_booking?.id]);

    // Load templates and years for selection
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}get_years`)
            .then((response) => {
                let dbYears = response.data;
                dbYears.push({ label: "All years", value: 0 });
                dbYears.sort((a, b) => a.value - b.value);
                setYears(dbYears);
            })
            .catch(() => alert("Year load failed"));

        axios.get(`${process.env.REACT_APP_API_URL}templates`).then((response) => {
            setTemplates(response.data);
            setTemplateCategories(Object.keys(response.data));
        });

        axios.get(`${process.env.REACT_APP_API_URL}whatsapp_templates`).then((response) => setWhatsappTemplates(response.data));
    }, []);

    // Filter templates based on the search term
    const filteredWhatsappTemplates = whatsappTemplates.filter(
        (template) => template.title.toLowerCase().includes(searchTerm.toLowerCase()) || template.message.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredTemplates = templateCategories.reduce((acc, title) => {
        acc[title] = templates[title].flatMap((template) => {
            if (template.attach_aos === 1) {
                return aosRecords
                    .map((aos) => ({
                        ...template,
                        title: `${template.title} - ${aos.unit?.name}`, // Append AOS unit to the title
                        aosUnit: aos.unit_id, // Include aosUnit as a separate field
                    }))
                    .filter(
                        (aosTemplate) => aosTemplate.title.toLowerCase().includes(searchTerm.toLowerCase()) || aosTemplate["message_" + platform]?.toLowerCase().includes(searchTerm.toLowerCase())
                    );
            }
            return template.title.toLowerCase().includes(searchTerm.toLowerCase()) || template["message_" + platform]?.toLowerCase().includes(searchTerm.toLowerCase())
                ? [{ ...template, aosUnit: null }]
                : [];
        });
        return acc;
    }, {});

    return (
        <Menu
            as="div"
            className="z-10 relative inline-block text-left bg-primary px-2 -mt-6 self-center py-1 rounded-xl hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
        >
            <div>
                <Menu.Button className="rounded-full flex items-center text-white hover:cursor-pointer ">
                    <span className="sr-only">Open options</span>
                    <FontAwesomeIcon className="h-5 w-5" aria-hidden="true" icon={faTableLayout} />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-80 whitespace-nowrap h-80 overflow-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {/* Search Input */}
                    <div className="p-4">
                        <input type="text" placeholder="Search templates..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="w-full p-2 border rounded-md" />
                    </div>

                    {platform === "whatsapp" && (
                        <div>
                            <p className="text-center p-4 bg-dark_background text-white focus:outline-none">System Approved Templates</p>
                            {years &&
                                years.length > 0 &&
                                years.map((year) => (
                                    <Fragment key={year.value}>
                                        <p className="text-center p-4 bg-dark_background text-white focus:outline-none">{year.label}</p>
                                        {filteredWhatsappTemplates.length > 0 &&
                                            filteredWhatsappTemplates.map(
                                                (template) =>
                                                    year.value === template.year_id && (
                                                        <Menu.Item key={template.id}>
                                                            <button
                                                                type="submit"
                                                                className="whitespace-nowrap w-min hover:bg-gray-200 hover:border-gray-200 block px-4 py-2 text-sm"
                                                                onClick={() => {
                                                                    setFieldValue("template", template.id);
                                                                    setFieldValue("platform", "whatsapp");
                                                                    setFieldValue("message", template.message);
                                                                    setFieldValue("template_name", template.title);
                                                                }}
                                                            >
                                                                <p className="text-left font-medium">{template.title}</p>
                                                                <p className="w-full text-gray-500">{template.message}</p>
                                                            </button>
                                                        </Menu.Item>
                                                    )
                                            )}
                                    </Fragment>
                                ))}
                        </div>
                    )}
                    {templateCategories &&
                        templateCategories.map(
                            (title) =>
                                filteredTemplates[title].length > 0 && (
                                    <div key={`template_${title}`}>
                                        <p className="text-center p-4 bg-dark_background text-white focus:outline-none">{title}</p>
                                        {filteredTemplates[title].map((template) =>
                                            template["message_" + platform] && template["message_" + platform] !== "null" && template["message_" + platform] !== "" ? (
                                                <Menu.Item key={`${template.id}_${template.aosUnit || ""}`}>
                                                    <a
                                                        href="#"
                                                        className="whitespace-nowrap w-min hover:bg-blue-200 hover:border-blue-200 block px-4 py-2 text-sm"
                                                        onClick={() => {
                                                            setFieldValue("message", template["message_" + platform]);
                                                            setFieldValue("files", template.files ? JSON.parse(template.files) : []);
                                                            setFieldValue("template", template.id);
                                                            setFieldValue("template_name", template.title);
                                                            setFieldValue("aos_unit", template.aosUnit);
                                                        }}
                                                    >
                                                        <p className="font-medium">{template.title}</p>
                                                        <p className="w-full text-gray-500">{template["message_" + platform]}</p>
                                                    </a>
                                                </Menu.Item>
                                            ) : (
                                                <div key={template.id}></div>
                                            )
                                        )}
                                    </div>
                                )
                        )}
                    <Menu.Item>
                        <div className="py-4">
                            <hr />
                            <a href="/settings" target="_blank" className="p-4 text-primary hover:text-primary_hover focus:outline-none">
                                <FontAwesomeIcon icon={faPenToSquare} /> Edit Normal Templates
                            </a>
                        </div>
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
