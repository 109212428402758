import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { faChartNetwork, faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";

// import { doc } from "firebase/firestore";

import CustomButton from "../Helpers/CustomButton";
import axios from "axios";
import NotificationToast from "../Helpers/NotificationToast";
import { getBooking } from "../../store/booking/bookingActions";
import { toast } from "react-toastify";

export default function GridBiometrics({ setDoc, db }) {
    const [biometricsLoading, setBiometricsLoading] = useState(true);
    const [ignoreRefresh, setIgnoreRefresh] = useState(false);

    const { current_booking } = useSelector((state) => state.booking);
    const dispatch = useDispatch();

    useEffect(async () => {
        await getBiometricAccount();
    }, []);

    async function getBiometricAccount() {
        setBiometricsLoading(false);
    }

    async function createBiometricAccount(user_id) {
        setBiometricsLoading(true);
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}create_biometric_account`,
            data: {
                user_id: user_id,
            },
        })
            .then((response) => {
                setIgnoreRefresh(true);
                dispatch(getBooking(current_booking.id));
                toast(<NotificationToast title={"Biometrics account created"} message={"Biometrics account created"} />);
            })
            .catch((response) => {
                toast(<NotificationToast title={"Biometrics Issue"} message={response.data} type="error" />);
            })
            .finally(() => {
                setBiometricsLoading(false);
            });
    }
    async function switchBiometricAccount(user_id) {
        setBiometricsLoading(true);
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}switch_biometric_account`,
            data: {
                user_id: user_id,
            },
        })
            .then((response) => {
                setIgnoreRefresh(true);
                dispatch(getBooking(current_booking.id));
                toast(<NotificationToast title={"Biometrics account switched"} message={"Biometrics account switched"} />);
            })
            .catch((response) => {
                toast(<NotificationToast title={"Biometrics Issue"} message={response.data} type="error" />);
            })
            .finally(() => {
                setBiometricsLoading(false);
            });
    }

    async function disableBuildingAccess(user_id) {
        console.log(user_id);
        setBiometricsLoading(true);
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}tenant/revoke-access`,
            data: {
                user_id: user_id,
            },
        })
            .then((response) => {
                setIgnoreRefresh(true);
                setBiometricsLoading(false);
                dispatch(getBooking(current_booking.id));
                toast(<NotificationToast title={"Building Access Revoked"} message={response.data.message} />);
                console.log("the response after the call:", response);
            })
            .catch((error) => {
                setBiometricsLoading(false);
                console.log("Error during building access revocation:", error);
                alert("Revoking building access failed.");
            });
    }

    async function enableBuildingAccess(user_id) {
        console.log(user_id);
        setBiometricsLoading(true);
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}tenant/enable-access`,
            data: {
                user_id: user_id,
            },
        })
            .then((response) => {
                setIgnoreRefresh(true);
                setBiometricsLoading(false);
                dispatch(getBooking(current_booking.id));
                toast(<NotificationToast title={"Building Access Enabled"} message={response.data.message} />);
                console.log("the response after the call:", response);
            })
            .catch((error) => {
                setBiometricsLoading(false);
                console.log("Error during building access enabling:", error);
                alert("Enabling building access failed.");
            });
    }

    return (
        <>
            <>
                <p>
                    HikCental Person ID: <span className="font-bold">{current_booking?.user?.hik_user_id || "N/A"}</span>
                </p>
                <p>
                    HikCental Person Code: <span className="font-bold">{current_booking?.user?.hik_person_id || "N/A"}</span>
                </p>
                <br />
            </>
            {current_booking?.user?.hik_user_id == null && (
                <>
                    <CustomButton text="Create Biometrics Account" styling="w-full mb-2" icon={faPlus} onClick={() => createBiometricAccount(current_booking?.user?.id)} loading={biometricsLoading} />
                </>
            )}

            {current_booking?.user?.active_building_access ? (
                <CustomButton text="Disable Biometric Access" styling="w-full mb-2" icon={faChartNetwork} onClick={() => disableBuildingAccess(current_booking?.user.id)} loading={biometricsLoading} />
            ) : (
                <CustomButton text="Enable Biometric Access" styling="w-full mb-2" icon={faChartNetwork} onClick={() => enableBuildingAccess(current_booking?.user.id)} loading={biometricsLoading} />
            )}
        </>
    );
}
