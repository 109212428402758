import React, { useEffect, useState, Fragment, useRef } from "react";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import SettingsTextArea from "../../components/Forms/SettingsTextarea";
import SubmitButton from "../../components/Forms/SubmitButton";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";

export default function WhatsappTemplatesPage() {
    const [templates, setTemplates] = useState([]);
    const [template, setTemplate] = useState({});
    const [loading, setLoading] = useState(true);
    const [openPopup, setOpenPopup] = useState(false);
    const cancelButtonRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredTemplates, setFilteredTemplates] = useState([]);

    useEffect(() => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}whatsapp_templates_all`,
        })
            .then((response) => {
                setTemplates(response.data);
                setLoading(false);
            })
            .catch((error) => {
                alert("Failed to get templates");
                setLoading(false);
            });
    }, []);

    // Filter templates based on the search term
    useEffect(() => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = templates.filter((template) => template.title.toLowerCase().includes(lowerCaseSearchTerm) || template.message.toLowerCase().includes(lowerCaseSearchTerm));
        setFilteredTemplates(filtered);
    }, [searchTerm, templates]);

    async function editTemplate(id) {
        if (id > 0) {
            setTemplate(templates.find((template) => template.id === id));
        } else {
            setTemplate({
                id: 0,
                title: "",
                message: "",
                approved: 1,
            });
        }

        setOpenPopup(1);
    }

    async function deleteTemplate(id) {
        if (window.confirm("Are you sure?")) {
            setLoading(true);
            await axios({
                method: "delete",
                url: `${process.env.REACT_APP_API_URL}delete_whatsapp_template/${id}`,
            })
                .then((response) => {
                    setLoading(true);
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}whatsapp_templates_all`,
                    })
                        .then((response) => {
                            setTemplates(response.data);
                            setLoading(false);
                        })
                        .catch((error) => {
                            alert("Failed to get templates");
                            setLoading(false);
                        });
                })
                .catch((error) => {
                    alert("Failed to delete template");
                    setLoading(false);
                });
        }
    }

    return (
        <div className="container p-5 mx-auto">
            <div className="flex justify-between items-center">
                <h1 className="font-bold mb-5">Whatsapp Templates</h1>

                <div className="flex space-x-4">
                    <button className="bg-primary text-white px-4 py-1 rounded-full" onClick={() => editTemplate(0)}>
                        Add Template
                    </button>

                    <Link to="/settings_new" className="bg-primary text-white px-4 py-2 rounded-full">
                        Back to Settings
                    </Link>
                </div>
            </div>

            <div className="mb-4">
                <input type="text" placeholder="Search templates..." className="w-full px-4 py-2 border rounded-lg" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>

            {loading === true ? (
                <p>Loading...</p>
            ) : (
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Title
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Message
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {filteredTemplates.length > 0 ? (
                            filteredTemplates.map((template) => (
                                <tr key={template.id}>
                                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {template.title}
                                        <br />
                                        {template.approved == 1 ? "Approved" : "Disabled"}
                                    </td>
                                    <td className="px-3 py-4 text-sm text-gray-500">{template.message}</td>
                                    <td className="px-3 py-4 text-sm text-gray-500 space-y-2">
                                        <button className="bg-primary text-white px-4 py-1 rounded-full w-full" onClick={() => editTemplate(template.id)}>
                                            EDIT
                                        </button>
                                        <button className="bg-primary text-white px-4 py-1 rounded-full w-full" onClick={() => deleteTemplate(template.id)}>
                                            DELETE
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center py-4 text-gray-500">
                                    No templates found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}

            <Transition.Root show={openPopup === 1} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <Formik
                                        initialValues={template}
                                        enableReinitialize="true"
                                        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                            setSubmitting(true);

                                            const formData = new FormData();
                                            for (const key in values) {
                                                if (values.hasOwnProperty(key)) {
                                                    formData.append(key, values[key]);
                                                }
                                            }

                                            // Append file if it exists
                                            if (values.file) {
                                                formData.append("file", values.file);
                                            }

                                            await axios({
                                                method: "post",
                                                url: `${process.env.REACT_APP_API_URL}store_whatsapp_template`,
                                                data: formData,
                                            })
                                                .then((resp) => {
                                                    setLoading(true);
                                                    axios({
                                                        method: "get",
                                                        url: `${process.env.REACT_APP_API_URL}whatsapp_templates_all`,
                                                    })
                                                        .then((response) => {
                                                            setTemplates(response.data);
                                                            setLoading(false);
                                                        })
                                                        .catch((error) => {
                                                            alert("Failed to get templates");
                                                            setLoading(false);
                                                        });
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    console.log(error.response);
                                                })
                                                .finally(() => {
                                                    setSubmitting(false);
                                                    setOpenPopup(false);
                                                });
                                        }}
                                    >
                                        {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                            <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                    <div className="space-y-4">
                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <Field name="title" type="text" as={SettingsTextInput} label="Title" />
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Status"
                                                                    name="approved"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.approved}
                                                                    touched={touched.approved}
                                                                    error={errors.approved}
                                                                    options={[
                                                                        { value: 1, label: "Active" },
                                                                        { value: 0, label: "Disabled" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div className="col-span-2">
                                                                <SettingsTextArea
                                                                    name="message"
                                                                    placeholder="Message"
                                                                    errors={errors.message}
                                                                    emojis={true}
                                                                    setFieldValue={setFieldValue}
                                                                    handleChange={handleChange}
                                                                    value={values.message}
                                                                    wysiwyg={false}
                                                                />
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Header Type"
                                                                    name="header_type"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.header_type}
                                                                    touched={touched.header_type}
                                                                    error={errors.header_type}
                                                                    options={[
                                                                        { value: 0, label: "None" },
                                                                        { value: 1, label: "Image" },
                                                                        { value: 2, label: "Document" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.header_type > 0 && (
                                                                <div>
                                                                    {values.id > 0 ? (
                                                                        <div className="mt-4">
                                                                            <label htmlFor="file_id" className="text-sm font-medium text-gray-700 flex items-baseline justify-between mb-2">
                                                                                Upload a File
                                                                            </label>
                                                                            {values.file_path ? (
                                                                                <>
                                                                                    {values.header_type == 1 && <img src={process.env.REACT_APP_LARAVEL_URL + "storage/" + values.file_path} />}
                                                                                    {values.header_type == 2 && (
                                                                                        <a href={process.env.REACT_APP_LARAVEL_URL + "storage/" + values.file_path} target="_blank">
                                                                                            Download PDF
                                                                                        </a>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <input
                                                                                    id="file"
                                                                                    name="file"
                                                                                    type="file"
                                                                                    onChange={(event) => {
                                                                                        setFieldValue("file", event.currentTarget.files[0]);
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="mt-4">File upload only works after saving</div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                        <SubmitButton
                                                            name="Save"
                                                            disabled={!isValid || isSubmitting || !dirty}
                                                            isSubmitting={isSubmitting}
                                                            icon={faSave}
                                                            text="Save"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />

                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                                            onClick={() => setOpenPopup(false)}
                                                            ref={cancelButtonRef}
                                                        >
                                                            CLOSE
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
}
