import React, { Fragment, useState, useRef } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import { getBooking } from "../../store/booking/bookingActions";

export default function ModalLinkChat({ current_booking, setLoading }) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const searchInput = useRef(null);
  const [doMerge, setDoMerge] = useState(false);
  const [mergeBooking, setMergeBooking] = useState({});
  const [currentType, setCurrentType] = useState("Student");
  const [linkedType, setLinkedType] = useState("Student");

  async function searchFrm(e) {
    if (e.code === "Enter" || e === false) {
      setSearching(true);
      await axios
        .post(`${process.env.REACT_APP_API_URL}searchBookings`, {
          search: searchInput.current.value,
          renewals: true,
        })
        .then((response) => {
          setSearchResults(response.data);
          setSearching(false);
        })
        .catch(() => {
          setSearchResults([]);
          setSearching(false);
        });
    }
  }

  async function saveLinkChat() {
    if (mergeBooking.id && current_booking.id) {
      await axios
        .post(`${process.env.REACT_APP_API_URL}save_link_chat`, {
          booking_id: current_booking.id,
          type: currentType,
          linked_booking_id: mergeBooking.id,
          linked_type: linkedType,
        })
        .then(() => {
          setDoMerge(false);
          setLoading(true);
          dispatch(closeModal());
        })
        .catch((error) => {
          console.log(error);
          setDoMerge(false);
        });
    } else {
      alert("Error: Missing booking information.");
    }
  }

  function handleMerge(res) {
    setDoMerge(true);
    setMergeBooking(res);
  }

  function handleCancel() {
    setDoMerge(false);
    setMergeBooking({});
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => (doMerge === false ? dispatch(closeModal()) : "")}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() =>
                      doMerge === false ? dispatch(closeModal()) : ""
                    }
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={faTimes}
                    />
                  </button>
                </div>

                <div className="pr-8 w-full">
                  {doMerge === false ? (
                    <>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h1"
                          className="text-lg font-medium text-gray-900"
                        >
                          Link a Chat to {current_booking?.booking_reference}
                        </Dialog.Title>
                        <div>
                          <label className="block text-sm font-medium mb-2 text-gray-700">
                            Search
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              className="block w-full font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary"
                              ref={searchInput}
                              placeholder="Type something to search"
                              onKeyUp={(e) => searchFrm(e)}
                            />
                            <button
                              className="ml-4 text-white uppercase px-4 py-2 rounded-lg border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 mt-1"
                              onClick={() => searchFrm(false)}
                            >
                              Search
                            </button>
                          </div>
                        </div>

                        <div className="mt-5">
                          {searching === true ? (
                            "Searching..."
                          ) : (
                            <>
                              {searchResults.length > 0 ? (
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        Booking
                                      </th>
                                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Name
                                      </th>
                                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Location
                                      </th>
                                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Status
                                      </th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200 bg-white">
                                    {searchResults.map((res) => (
                                      <tr key={"search_" + res.id}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                          {res.booking_reference} {" ("}{" "}
                                          {res.year} {") "}
                                          {res.active_booking === res.id
                                            ? "(Active)"
                                            : ""}
                                        </td>
                                        <td className="whitespace-wrap px-3 py-4 text-xs text-gray-500">
                                          <p>
                                            {res.name} {res.surname}
                                          </p>
                                          <p>{res.email}</p>
                                          <p>{res.cell}</p>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                                          {res.location}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                                          {res.booking_merged_into ? (
                                            <p>Merged</p>
                                          ) : (
                                            <p>Active</p>
                                          )}
                                        </td>
                                        <td>
                                          <button
                                            onClick={() => handleMerge(res)}
                                            className="text-white uppercase px-4 py-2 rounded-lg border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 mt-1"
                                          >
                                            Link
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              ) : (
                                "No results found!"
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h1"
                        className="text-lg font-medium text-gray-900"
                      >
                        Confirm Chat Linking
                      </Dialog.Title>
                      <div className="flex justify-between mt-5">
                        {/* Current Booking */}
                        <div className="w-1/2 pr-4">
                          <h2 className="font-semibold text-gray-800">
                            Current Booking
                          </h2>
                          <p>{current_booking?.booking_reference}</p>
                          <p>
                            {current_booking?.user?.name}{" "}
                            {current_booking?.user?.surname}
                          </p>
                          <p>{current_booking?.user?.email}</p>
                          <label className="block text-sm font-medium mb-2 text-gray-700 mt-4">
                            Select Type
                          </label>
                          <select
                            value={currentType}
                            onChange={(e) => setCurrentType(e.target.value)}
                            className="block w-full px-3 py-2 border border-gray-300 rounded-md"
                          >
                            <option value="Student">Student</option>
                            <option value="Parent">Parent</option>
                            <option value="Sponsor">Sponsor</option>
                            <option value="Room Mate">Room Mate</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>

                        {/* Linked Booking */}
                        <div className="w-1/2 pl-4">
                          <h2 className="font-semibold text-gray-800">
                            Booking to Link
                          </h2>
                          <p>{mergeBooking?.booking_reference}</p>
                          <p>
                            {mergeBooking?.name} {mergeBooking?.surname}
                          </p>
                          <p>{mergeBooking?.email}</p>

                          <label className="block text-sm font-medium mb-2 text-gray-700 mt-4">
                            Select Type
                          </label>
                          <select
                            value={linkedType}
                            onChange={(e) => setLinkedType(e.target.value)}
                            className="block w-full px-3 py-2 border border-gray-300 rounded-md"
                          >
                            <option value="Student">Student</option>
                            <option value="Parent">Parent</option>
                            <option value="Sponsor">Sponsor</option>
                            <option value="Room Mate">Room Mate</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>

                      <div className="mt-6 flex justify-center">
                        <button
                          onClick={handleCancel}
                          className="mr-4 text-gray-700 uppercase px-4 py-2 rounded-lg border text-sm focus:z-10 focus:outline-none border-gray-300 bg-gray-100 hover:bg-gray-200 focus:ring-gray-300 focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                        >
                          Cancel
                        </button>

                        <button
                          onClick={() => saveLinkChat()}
                          className="text-white uppercase px-4 py-2 rounded-lg border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                        >
                          Save Link
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
